import React, { Reducer, useReducer, useState } from 'react';
import * as Yup from 'yup';
import { observer } from 'mobx-react';
import {
    AppHeader,
    AppPage,
    IconButton,
    Form,
    AppFooter,
    ButtonPrimary,
    Button,
    useSnackbar,
    AppContent,
    ActionList,
    AppSectionHeader
} from '../../components-v2/shared';

import useStore from '../../store/useStore';
import {
    Icon,
    useAsyncEffect,
    handlePromise,
    InputDate,
    InputSelect,
    InputText,
    Table,
    useDrawer,
    InputTextarea,
    InputSwitch
} from '@lambdacurry/component-library';
import styles from './blast-schedule-editor.module.scss';
import TimeKeeper from 'react-timekeeper';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { FormikHelpers, FormikProps, isString } from 'formik';
import { BlastSchedule } from './BlastScheduleEditor.types';
import { EditBlastScheduleNameForm } from './EditBlastScheduleNameForm';
import { DateTime } from 'luxon';
import {
    blastScheduleEditorEditScheduleReducer,
    BlastScheduleEditorEditScheduleReducerAction,
    BlastScheduleEditorEditScheduleReducerState
} from './BlastScheduleEditor.helpers';
import classNames from 'classnames';
import { LeadNurtureTemplate } from '../EmailEditor/EmailEditor.types';
import { LeadNurtureAppSource } from '../EmailEditor/EmailEditorList.helpers';
import { ListSegment } from '../ListSegmentEditor/ListSegmentEditor.types';
import { HelpDrawer, HelpDrawerName } from '../HelpDrawer/HelpDrawer';
import { FEATURES } from '../../types/Features';

export interface BlastScheduleFormEntry {
    id?: number;
    blast_schedule_id: number;
    active: boolean;
    hours_from_lead_event: number;
    allow_catchup?: boolean;
    send_via_email: boolean;
    send_via_sms: boolean;
    sms_message?: string;
    lead_nurture_template_id: number;
    deleted_at?: DateTime;
}

export interface EditBlastScheduleForm {
    lead_nurture_template_id?: number;
    add_list_segment?: { id: number; name: string };
    sms_message?: string;
    send_via_email: boolean;
    send_via_sms: boolean;
    blast_schedule_date?: string | null | Date;
    blast_schedule_time: string | null;
    list_segments?: { id: number; name: string }[];
}

export const BlastScheduleEditorEditSchedulePage = observer(() => {
    const { store } = useStore();
    const { router, Api, features } = store;
    const { addSnackbar } = useSnackbar();
    const { companyId, scheduleId } = router.params;
    const [listSegments, setListSegments] = useState<ListSegment[]>([]);
    const [templates, setTemplates] = useState<LeadNurtureTemplate[]>([]);

    const isSMSReady = () => {
        return features[FEATURES.sms_messages] && (features as any)[FEATURES.amount_twilio_numbers] > 0;
    };

    const characterCount = (txt?: string | null): number => {
        if (!txt) {
            return 0;
        }
        return txt.length;
    };

    const [state, dispatch] = useReducer<
        Reducer<BlastScheduleEditorEditScheduleReducerState, BlastScheduleEditorEditScheduleReducerAction>
    >(blastScheduleEditorEditScheduleReducer, {
        modal: {
            active: 'none',
            data: {}
        },
        schedule: {} as BlastSchedule
    });
    const {
        isEditingName,
        schedule
        // modal
    } = state;

    const {
        drawerActions: { toggleDrawer }
    } = useDrawer();

    const fetchSchedule = async () => {
        const [response, err] = await handlePromise<{ data: BlastSchedule }>(
            Api.client.get(`company/${companyId}/blast-schedules/${scheduleId}`)
        );

        if (err || !response) {
            // TODO: handle error
            return;
        }

        dispatch({ name: 'setBlastSchedule', payload: response.data });
    };

    const BlastEntryFormValidationSchema = Yup.object({
        entries: Yup.array().of(
            Yup.object({
                active: Yup.boolean().required(),
                lead_nurture_template_id: Yup.number().required('Please select an email template.'),
                send_via_email: Yup.boolean().when('send_via_sms', {
                    is: false,
                    then: Yup.boolean().oneOf([true]).required('Must send via email if SMS not selected')
                })
            })
        )
    });

    const fetchTemplates = async () => {
        const [response, err] = await handlePromise<{ count: number; data: { data: LeadNurtureTemplate[] } }>(
            Api.client.get(`company/${companyId}/lead-nurture-templates`)
        );

        if (err || !response) {
            // TODO: handle error
            return;
        }

        setTemplates(response.data.data);
    };

    const smsTemplateVariables = [
        '{{companyName}}',
        '{{companyEmail}}',
        '{{companyPhone}}',
        '{{replyToEmail}}',
        '{{customerFirstName}}',
        '{{customerLastName}}',
        '{{customerPhone}}',
        '{{customerEmail}}',
        '{{{scheduleGenieAppLink}}}',
        '{{{concessionManagerAppLink}}}',
        '{{{bestPriceNowAppLink}}}',
        '{{{textUsNowAppLink}}}',
        '{{{chatAppLink}}}',
        '{{{incomeCalculatorAppLink}}}'
    ];

    const fetchListSegments = async () => {
        const [getListSegmentsResponse, getListSegmentsError] = await handlePromise<{
            data: { count: number; data: ListSegment[] };
        }>(Api.client.get(`company/${companyId}/list-segments`));

        if (getListSegmentsError || !getListSegmentsResponse) {
            // TODO: handle error
            return;
        }

        setListSegments(getListSegmentsResponse.data.data);
    };

    const fetchData = async () => {
        await fetchSchedule();
        await fetchListSegments();
        await fetchTemplates();
    };

    useAsyncEffect(fetchData);

    const handleEditScheduleName: (
        values: { name: string },
        formikHelpers: FormikHelpers<{ name: string }>
    ) => void = async values => {
        const { company_id, ...updatedSchedule } = schedule;
        const [response, err] = await handlePromise<{ data: BlastSchedule }>(
            Api.client.patch(`company/${companyId}/blast-schedules`, { ...updatedSchedule, name: values.name })
        );

        if (err || !response) {
            // TODO: handle error
            return;
        }

        dispatch({ name: 'setIsEditingName', payload: false });
        dispatch({ name: 'setBlastSchedule', payload: response.data });
    };

    const handleFormSubmit: (
        values: EditBlastScheduleForm,
        formikHelpers: FormikHelpers<EditBlastScheduleForm>
    ) => void = async (values, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        const data = {
            ...schedule
        };

        if (!values.blast_schedule_date) {
            data.scheduled_at = null;
        }

        if (!!values.blast_schedule_date && !!values.blast_schedule_time) {
            const isoDateUTC = new Date(values.blast_schedule_date).toISOString();
            const [hours, minutes] = values.blast_schedule_time.split(':').map(item => Number(item));
            data.scheduled_at = DateTime.fromISO(isoDateUTC).startOf('day').plus({ hours, minutes }).toISO();
        }

        if (values.list_segments && values.list_segments.length > 0) {
            data.list_segments = values.list_segments.map(seg => seg.id);
        } else {
            data.list_segments = [];
        }

        data.lead_nurture_template_id = values.lead_nurture_template_id;
        data.sms_message = values.sms_message;
        data.send_via_email = values.send_via_email;
        data.send_via_sms = isSMSReady() && values.send_via_sms;

        const [response, err] = await handlePromise<{ data: BlastSchedule }>(
            Api.client.patch(`company/${companyId}/blast-schedules`, data)
        );

        formikHelpers.setSubmitting(false);

        if (err || !response) {
            // TODO: handle error
            return;
        }

        dispatch({ name: 'setBlastSchedule', payload: response.data });

        formikHelpers.resetForm();
        addSnackbar(`Blast Schedule has been updated.`, {
            variant: 'success'
        });
    };

    const toShortTime = (iso: string): string => {
        return DateTime.fromISO(iso).toFormat('HH:mm');
    };

    const filterTemplates = (templateList: LeadNurtureTemplate[]): LeadNurtureTemplate[] => {
        return templateList.filter(t => t.app_source_type === LeadNurtureAppSource.customer);
    };

    const isDraft = (values: EditBlastScheduleForm): boolean => {
        const segmentsReady = values.list_segments && values.list_segments.length > 0;
        const blastDateReady = values.blast_schedule_date && values.blast_schedule_time;
        const emailTemplateReady =
            !values.send_via_email || (values.lead_nurture_template_id && values.lead_nurture_template_id > 0);
        const smsReady = !values.send_via_sms || (values.sms_message && values.sms_message.length > 0);
        const deliveryMethodsReady = values.send_via_email || values.send_via_sms;
        if (segmentsReady && blastDateReady && emailTemplateReady && smsReady && deliveryMethodsReady) {
            return false;
        }
        return true;
    };

    return (
        <AppPage loading={!schedule.name}>
            <AppHeader
                title={`${!isEditingName ? schedule.name : ''}`}
                onBack={
                    !isEditingName
                        ? () => router.goTo(DefaultRoutes.BlastScheduleEditorListPage, { companyId }, store)
                        : undefined
                }
            >
                {isEditingName ? (
                    <EditBlastScheduleNameForm
                        name={schedule.name}
                        onSubmit={handleEditScheduleName}
                        onCancel={() => dispatch({ name: 'setIsEditingName', payload: false })}
                    />
                ) : (
                    <IconButton
                        className={styles['schedule-editor-edit-name-button']}
                        icon="pencil"
                        hidden={!!schedule.completed_at}
                        onClick={() => dispatch({ name: 'setIsEditingName', payload: true })}
                    />
                )}
                <ActionList position="end">
                    <IconButton icon="question" onClick={() => toggleDrawer({ name: HelpDrawerName })} />
                </ActionList>
            </AppHeader>
            <Form
                enableReinitialize
                validateOnMount
                confirmUnsavedChanges={true}
                unsavedChangesConfig={{
                    containerQuerySelectorAll: `[class*="app-header "] ~ *`
                }}
                initialValues={{
                    list_segments: listSegments.filter(as => schedule.list_segments?.includes(as.id)),
                    lead_nurture_template_id: schedule.lead_nurture_template_id,
                    sms_message: schedule.sms_message,
                    send_via_email: schedule.send_via_email || false,
                    send_via_sms: schedule.send_via_sms || false,
                    blast_schedule_date: isString(schedule.scheduled_at)
                        ? DateTime.fromISO(schedule.scheduled_at).toJSDate()
                        : schedule.scheduled_at,
                    blast_schedule_time: schedule.scheduled_at ? toShortTime(schedule.scheduled_at) : '8:00'
                }}
                onSubmit={handleFormSubmit}
                validationSchema={BlastEntryFormValidationSchema}
            >
                {(formikProps: FormikProps<EditBlastScheduleForm>) => {
                    return (
                        <>
                            <AppContent className="blast-editor-form">
                                <AppContent>
                                    <AppSectionHeader title={<>Delivery Methods</>}>
                                        <ActionList position="end">
                                            <InputSwitch
                                                label="Send via Email"
                                                name="send_via_email"
                                                disabled={!!schedule.completed_at}
                                                formikProps={formikProps}
                                            />
                                            <InputSwitch
                                                label="Send via SMS"
                                                name="send_via_sms"
                                                disabled={!!schedule.completed_at || !isSMSReady()}
                                                formikProps={formikProps}
                                            />
                                        </ActionList>
                                    </AppSectionHeader>
                                    {formikProps.values.send_via_sms && (
                                        <div>
                                            {' '}
                                            <AppSectionHeader title="SMS Message" />
                                            <InputSelect
                                                name="placeholders"
                                                placeholder="Template variables"
                                                optionValueKey="id"
                                                optionLabelKey="name"
                                                onChange={(event: React.ChangeEvent<any>, arg: string) => {
                                                    const newAlt = formikProps.values['sms_message'] + ' ' + arg;
                                                    formikProps.setFieldValue('sms_message', newAlt);
                                                }}
                                                options={smsTemplateVariables.map(item => {
                                                    return { id: item, name: item };
                                                })}
                                            />
                                            <InputTextarea name={`sms_message`} formikProps={formikProps} />
                                            <div>
                                                Character Count Estimate:{' '}
                                                <span style={{ fontWeight: 'bolder' }}>
                                                    {characterCount(formikProps.values['sms_message'])}
                                                </span>
                                            </div>
                                            <div
                                                style={{ color: 'red' }}
                                                hidden={characterCount(formikProps.values['sms_messagee']) < 140}
                                            >
                                                Warning: Messages with more than 140 characters might be broken up into
                                                multiple texts.
                                            </div>
                                        </div>
                                    )}

                                    {formikProps.values.send_via_email && (
                                        <AppSectionHeader
                                            title={
                                                <>
                                                    <Icon name="mailOutline" /> Select Email Template
                                                </>
                                            }
                                        >
                                            <ActionList position="end">
                                                <InputSelect
                                                    label="Email Template"
                                                    disabled={!!schedule.completed_at}
                                                    name="lead_nurture_template_id"
                                                    options={filterTemplates(templates)}
                                                    className={styles['blast-editor-form-input']}
                                                    formikProps={formikProps}
                                                    autocompleteConfig={{ multiple: false, disableClearable: false }}
                                                    contentEditable={false}
                                                    allowCreateOption={false}
                                                    inputProps={{ autoComplete: 'disable' }}
                                                    optionValueKey="id"
                                                    optionLabelKey="name"
                                                />
                                            </ActionList>
                                        </AppSectionHeader>
                                    )}

                                    {!schedule.completed_at ? (
                                        <>
                                            <AppSectionHeader
                                                title={
                                                    <>
                                                        <Icon name="schedule" /> Blast Date
                                                    </>
                                                }
                                            >
                                                <ActionList position="end">
                                                    <InputDate
                                                        name="blast_schedule_date"
                                                        unselectable="on"
                                                        disabled={!!schedule.completed_at}
                                                        label="Blast Date"
                                                        className={classNames(styles['blast-editor-form-input'])}
                                                        labelPlacement="inset"
                                                        value={'2023-09-09'}
                                                        disablePast={true}
                                                        formikProps={formikProps}
                                                    />
                                                </ActionList>
                                            </AppSectionHeader>
                                            <AppSectionHeader
                                                title={
                                                    <>
                                                        <Icon name="clock" /> Blast Time
                                                    </>
                                                }
                                            >
                                                <ActionList position="end">
                                                    <InputText
                                                        hidden={true}
                                                        formikProps={formikProps}
                                                        name="blast_schedule_time"
                                                        value={formikProps.values.blast_schedule_time}
                                                    />
                                                    <TimeKeeper
                                                        time={formikProps.values.blast_schedule_time}
                                                        onChange={(data: any) => {
                                                            formikProps.setFieldValue(
                                                                'blast_schedule_time',
                                                                data.formatted24
                                                            );
                                                        }}
                                                    />
                                                </ActionList>
                                            </AppSectionHeader>
                                        </>
                                    ) : (
                                        <AppSectionHeader
                                            title={
                                                <>
                                                    <Icon name="schedule" /> Blast Date
                                                </>
                                            }
                                        >
                                            <ActionList position="end">
                                                <InputText
                                                    name="blast_schedule_date"
                                                    unselectable="on"
                                                    label="Blast Date"
                                                    disabled={true}
                                                    className={classNames(styles['blast-editor-form-input'])}
                                                    labelPlacement="inset"
                                                    value={schedule.completed_at}
                                                    formikProps={formikProps}
                                                />
                                            </ActionList>
                                        </AppSectionHeader>
                                    )}

                                    <AppSectionHeader
                                        title={
                                            <>
                                                <Icon name="filter" /> Selected List Segments
                                            </>
                                        }
                                    >
                                        {!schedule.completed_at && (
                                            <ActionList position="end">
                                                <InputText
                                                    name="list_segments"
                                                    hidden={true}
                                                    formikProps={formikProps}
                                                />
                                                <InputSelect
                                                    label="List Segments"
                                                    name="add_list_segment"
                                                    disabled={!!schedule.completed_at}
                                                    className={styles['blast-editor-form-input']}
                                                    options={listSegments.filter(
                                                        seg =>
                                                            !formikProps.values.list_segments
                                                                ?.map(ls => ls.id)
                                                                .includes(seg.id)
                                                    )}
                                                    optionLabelKey="name"
                                                    inputProps={{ autoComplete: 'disable' }}
                                                    formikProps={formikProps}
                                                    onChange={(evt, value) => {
                                                        formikProps.setFieldValue('list_segments', [
                                                            ...(formikProps.values.list_segments || []),
                                                            value
                                                        ]);
                                                    }}
                                                />
                                            </ActionList>
                                        )}
                                    </AppSectionHeader>
                                    {formikProps.values.list_segments && (
                                        <Table>
                                            <tbody>
                                                {formikProps.values.list_segments.map(segment => {
                                                    return (
                                                        <tr key={segment.id}>
                                                            <td className="clx-font-weight-bold">{segment.name}</td>
                                                            <td>
                                                                <ActionList position="end">
                                                                    <IconButton
                                                                        disabled={!!schedule.completed_at}
                                                                        icon="trash"
                                                                        onClick={() => {
                                                                            formikProps.setFieldValue(
                                                                                'list_segments',
                                                                                formikProps.values.list_segments?.filter(
                                                                                    ls => ls.id !== segment.id
                                                                                )
                                                                            );
                                                                        }}
                                                                    />
                                                                </ActionList>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    )}
                                </AppContent>
                            </AppContent>

                            <AppFooter className={styles['blast-schedule-editor-footer']} sticky={true}>
                                <div className="flex-spacer" />
                                {formikProps.dirty && formikProps.isValid && (
                                    <Button
                                        onClick={() => formikProps.resetForm()}
                                        className={styles['blast-schedule-editor-footer-cancel']}
                                        disabled={formikProps.isSubmitting}
                                    >
                                        Cancel
                                    </Button>
                                )}
                                <ButtonPrimary
                                    type="submit"
                                    disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}
                                >
                                    {formikProps.isSubmitting
                                        ? 'Saving...'
                                        : formikProps.dirty
                                        ? isDraft(formikProps.values)
                                            ? 'Save Draft'
                                            : 'Schedule'
                                        : isDraft(formikProps.values)
                                        ? 'Draft Saved'
                                        : 'Scheduled'}
                                </ButtonPrimary>
                            </AppFooter>
                        </>
                    );
                }}
            </Form>
            <HelpDrawer settings={{ title: 'Scheduled Blasts', embedFile: '/lead-nurturing/scheduled-blasts.html' }} />
        </AppPage>
    );
});
