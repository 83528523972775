import React, { FC, useState } from 'react';
import {
    ActionList,
    AppHeader,
    AppSection,
    Button,
    Modal,
    ModalActions,
    ModalHeader,
    useSnackbar
} from '../../../components-v2/shared';
import { Filters } from '../../Filters/Filters.helpers';
import { BaseRow, Importer, ImporterField } from '@clxmedia/react-csv-importer';
import '@clxmedia/react-csv-importer/dist/index.css';
import { handlePromise } from '@lambdacurry/component-library';
import useStore from '../../../store/useStore';

interface LeadCustomerData {
    firstname: string;
    lastname: string;
    email: string;
    phone?: string;
}

interface ImportResult {
    imported: number;
    duplicates: LeadCustomerData[];
    errors: string[];
}

export const LeadImport: FC<{ filters?: Filters }> = () => {
    const { store } = useStore();
    const { Api, router } = store;
    const { addSnackbar } = useSnackbar();
    const [resultModal, setResultModal] = useState(false);
    const [importResults, setImportResults] = useState<ImportResult | null>(null); // Single result object
    const [allRows, setAllRows] = useState<BaseRow[]>([]); // Accumulate rows across chunks

    async function doContactsUpload(rows: BaseRow[]) {
        // Filter rows with required fields
        const validRows = rows.filter(row => row.firstname && row.lastname && row.email);
        setAllRows(prev => [...prev, ...validRows]); // Accumulate rows locally
    }

    async function finalizeImport() {
        if (allRows.length === 0) {
            addSnackbar('No valid data to import.', { variant: 'warning' });
            return;
        }

        const [response, error] = await handlePromise(
            Api.client.post(`company/${router.params.companyId}/contacts/import`, allRows)
        );

        if (!response?.data || error) {
            console.error(error?.response);
            addSnackbar('Failed to import leads.', { variant: 'error' });
            setAllRows([]); // Reset on failure
            return;
        }

        setImportResults(response.data); // Set the final result once
        setResultModal(true);
        setAllRows([]); // Reset after success
    }

    const dupsToListItems = (result: ImportResult | null) => {
        if (!result || result.duplicates.length === 0) return <></>;
        const dupSet = new Set(result.duplicates); // Deduplicate within the single result
        return (
            <ul>
                {Array.from(dupSet).map((dup, idx) => (
                    <li key={`dup_${idx}`}>
                        {dup.firstname} {dup.lastname} ({dup.email})
                    </li>
                ))}
            </ul>
        );
    };

    const errorItems = (result: ImportResult | null) => {
        if (!result || result.errors.length === 0) return <>No errors</>;
        const errSet = new Set(result.errors); // Deduplicate errors
        return (
            <ul>
                {Array.from(errSet).map((err, idx) => (
                    <li key={`err_${idx}`}>{err}</li>
                ))}
            </ul>
        );
    };

    return (
        <>
            <AppHeader title="Import Contacts" />
            <AppSection>
                <Importer
                    dataHandler={async rows => {
                        // Accumulate rows for each chunk
                        await doContactsUpload(rows);
                    }}
                    defaultNoHeader={false}
                    restartable={true}
                    onStart={() => {
                        setAllRows([]); // Reset rows at the start of a new import
                        setImportResults(null); // Clear previous results
                    }}
                    onComplete={() => {
                        // Finalize the import after all chunks are processed
                        finalizeImport();
                    }}
                >
                    <ImporterField name="firstname" label="First Name" />
                    <ImporterField name="lastname" label="Last Name" />
                    <ImporterField name="email" label="Email" />
                    <ImporterField name="phone" label="Phone" optional />
                </Importer>
            </AppSection>
            <Modal isOpen={resultModal} closeButton={false}>
                <ModalHeader title="Import Results" />
                {importResults && (
                    <div>
                        <p>
                            <b>Imported Records:</b> {importResults.imported}
                        </p>
                        <p>
                            <b>Duplicate Records:</b> {importResults.duplicates.length}
                            {dupsToListItems(importResults)}
                        </p>
                        <p>
                            <b>Errors:</b> {errorItems(importResults)}
                        </p>
                    </div>
                )}
                <ModalActions>
                    <ActionList position="end">
                        <Button
                            onClick={() => {
                                setResultModal(false);
                                setImportResults(null); // Clear results on close
                            }}
                        >
                            Close
                        </Button>
                    </ActionList>
                </ModalActions>
            </Modal>
        </>
    );
};
