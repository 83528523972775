import React, { Reducer, useReducer } from 'react';
import { observer } from 'mobx-react';
import {
    IconButton,
    AppHeader,
    AppPage,
    AppContent,
    AppList,
    AppListItem,
    AppListItemContent,
    Button,
    Title,
    ButtonPrimary,
    Modal,
    ModalHeader,
    ModalActions,
    ButtonDanger,
    Form,
    InputText,
    ButtonUnstyled,
    ActionList,
    AppListHeader,
    useSnackbar
} from '../../components-v2/shared';

import useStore from '../../store/useStore';
import styles from './blast-schedule-editor.module.scss';
import { handlePromise, useAsyncEffect } from '../../util/async';
import {
    BlastScheduleEditorListReducerAction,
    BlastScheduleEditorListReducerState,
    blastScheduleEditorListReducer
} from './BlastScheduleEditor.helpers';
import { FormikHelpers, FormikProps } from 'formik';
import { Link } from 'mobx-router';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { CreateBlastScheduleFormValidationSchema } from './BlastScheduleEditor.validation';
import { BlastSchedule } from './BlastScheduleEditor.types';
import classNames from 'classnames';
import './blasts-list.scss';
import { DateTime } from 'luxon';
import { HelpDrawer, HelpDrawerName } from '../HelpDrawer/HelpDrawer';
import { Icon, useDrawer } from '@lambdacurry/component-library';
import { EmailSenderBundle } from '../../constants';

interface CreateBlastScheduleForm {
    name: string;
}

export const BlastScheduleEditorListPage = observer(() => {
    const { store } = useStore();
    const { router, Api } = store;
    const { companyId } = router.params;
    const { addSnackbar } = useSnackbar();
    const {
        drawerActions: { toggleDrawer }
    } = useDrawer();

    const [state, dispatch] = useReducer<
        Reducer<BlastScheduleEditorListReducerState, BlastScheduleEditorListReducerAction>
    >(blastScheduleEditorListReducer, {
        modal: {
            active: 'none',
            data: {}
        }
    });
    const { schedules, modal } = state;

    const fetchBlastSchedules = async () => {
        const [getBlastSchedulesResponse, getBlastSchedulesError] = await handlePromise<{
            data: { count: number; data: BlastSchedule[] };
        }>(Api.client.get(`company/${companyId}/blast-schedules`));

        if (getBlastSchedulesError || !getBlastSchedulesResponse) {
            // TODO: handle error
            return;
        }

        dispatch({ name: 'setSchedules', payload: getBlastSchedulesResponse.data.data });
    };

    const fetchSenderProfile = async () => {
        if (!companyId) {
            return;
        }
        const url = `/company/${companyId}/sender-profile`;
        const [response, error] = await handlePromise<{ data: EmailSenderBundle }>(Api.client.get(url));

        if (!response?.data || error) {
            console.error(error?.response);
            addSnackbar('Failed to fetch sender profile.', { variant: 'error' });
            return;
        }

        dispatch({ name: 'setCanCreate', payload: !!response.data.managedBy && response.data.managedBy !== 'global' });
    };

    const fetchData = async () => {
        await fetchSenderProfile();
        await fetchBlastSchedules();
    };

    useAsyncEffect(fetchData);

    const handleOpenModal = (modalName: string, modalData?: object) => {
        dispatch({ name: 'openModal', payload: modalName });

        if (modalData) {
            dispatch({ name: 'setModalData', payload: modalData });
        }
    };

    const handleCreateBlastSchedule: (
        values: CreateBlastScheduleForm,
        formikHelpers: FormikHelpers<CreateBlastScheduleForm>
    ) => void = async ({ name }, formikHelpers) => {
        const newBlastSchedule = {
            name,
            company_id: Number.parseInt(companyId, 10)
        };

        const [createBlastScheduleResponse, createBlastScheduleError] = await handlePromise<{ data: BlastSchedule }>(
            Api.client.post(`company/${companyId}/blast-schedules`, newBlastSchedule)
        );

        if (createBlastScheduleError || !createBlastScheduleResponse) {
            // TODO: handle error
            return;
        }

        formikHelpers.resetForm();
        router.goTo(
            DefaultRoutes.EditBlastSchedulePage,
            { companyId, scheduleId: createBlastScheduleResponse.data.id },
            store
        );
    };

    const handleDeleteBlastSchedule = async (selectedBlastSchedule?: BlastSchedule) => {
        if (!selectedBlastSchedule) {
            return;
        }

        const [deleteBlastScheduleResponse, deleteBlastScheduleError] = await handlePromise<{
            data: BlastSchedule;
        }>(Api.client.delete(`company/${companyId}/blast-schedules/${selectedBlastSchedule.id}`));

        if (deleteBlastScheduleError || !deleteBlastScheduleResponse) {
            // TODO: handle error
            return;
        }

        dispatch({ name: 'closeModal', payload: 'deleteBlastSchedule' });

        await fetchBlastSchedules();
    };

    const handleArchiveBlastSchedule = async (selectedBlastSchedule?: BlastSchedule) => {
        if (!selectedBlastSchedule) {
            return;
        }

        const [deleteBlastScheduleResponse, deleteBlastScheduleError] = await handlePromise<{
            data: BlastSchedule;
        }>(Api.client.delete(`company/${companyId}/blast-schedules/${selectedBlastSchedule.id}`));

        if (deleteBlastScheduleError || !deleteBlastScheduleResponse) {
            // TODO: handle error
            return;
        }

        dispatch({ name: 'closeModal', payload: 'deleteBlastSchedule' });

        await fetchBlastSchedules();
    };

    const localDateTime = (iso: string): string => {
        try {
            return DateTime.fromISO(iso).toLocaleString(DateTime.DATETIME_HUGE);
        } catch (e) {
            return iso;
        }
    };

    return (
        <>
            <AppPage>
                <AppHeader title="Scheduled Blasts">
                    <div className="flex-spacer" />
                    <ButtonPrimary onClick={() => handleOpenModal('createBlastSchedule')}>
                        Create Scheduled Blast
                    </ButtonPrimary>
                    <IconButton
                        style={{ marginLeft: 16 }}
                        icon="question"
                        onClick={() => toggleDrawer({ name: HelpDrawerName })}
                    />
                </AppHeader>
                <AppContent loading={!schedules} className={styles['schedule-editor-list-page-content']}>
                    {schedules && schedules?.length > 0 && (
                        <AppList className="blasts-list">
                            <AppListHeader className="blasts-list-header">
                                <div key="name">Blast Name</div>
                                <div key="status">Status</div>
                                <div key="options"></div>
                            </AppListHeader>
                            {schedules?.map(schedule => {
                                const {
                                    id,
                                    name,
                                    scheduled_at,
                                    completed_at,
                                    list_segments,
                                    lead_nurture_template_id,
                                    send_via_email
                                } = schedule;

                                return (
                                    <AppListItem key={id} className={classNames('blasts-list-item', {})}>
                                        <AppListItemContent
                                            title={
                                                <ButtonUnstyled
                                                    as={buttonProps => (
                                                        <Link
                                                            {...buttonProps}
                                                            view={DefaultRoutes.EditBlastSchedulePage}
                                                            params={{ companyId, scheduleId: id }}
                                                            store={store}
                                                        />
                                                    )}
                                                >
                                                    {name}
                                                </ButtonUnstyled>
                                            }
                                        />

                                        <div>
                                            {completed_at
                                                ? `Completed on ${localDateTime(completed_at)}`
                                                : scheduled_at &&
                                                  list_segments &&
                                                  list_segments.length > 0 &&
                                                  (!!lead_nurture_template_id || !send_via_email)
                                                ? `Scheduled for ${localDateTime(scheduled_at)}`
                                                : 'Draft'}
                                        </div>

                                        <ActionList position="end">
                                            {completed_at ? (
                                                <>
                                                    <IconButton
                                                        icon="analytics"
                                                        as={buttonProps => (
                                                            <Link
                                                                {...buttonProps}
                                                                view={DefaultRoutes.BlastScheduleReportPage}
                                                                params={{ companyId, scheduleId: id }}
                                                                store={store}
                                                            />
                                                        )}
                                                    />
                                                    <IconButton
                                                        icon="archive"
                                                        onClick={() =>
                                                            handleOpenModal('archiveBlastSchedule', {
                                                                selectedSchedule: schedule
                                                            })
                                                        }
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    {' '}
                                                    <IconButton
                                                        icon="pencil"
                                                        hidden={!!completed_at}
                                                        as={buttonProps => (
                                                            <Link
                                                                {...buttonProps}
                                                                view={DefaultRoutes.EditBlastSchedulePage}
                                                                params={{ companyId, scheduleId: id }}
                                                                store={store}
                                                            />
                                                        )}
                                                    />
                                                    <IconButton
                                                        icon="trash"
                                                        hidden={!!completed_at}
                                                        onClick={() =>
                                                            handleOpenModal('deleteBlastSchedule', {
                                                                selectedSchedule: schedule
                                                            })
                                                        }
                                                    />
                                                </>
                                            )}
                                        </ActionList>
                                    </AppListItem>
                                );
                            })}
                        </AppList>
                    )}

                    {schedules?.length === 0 && (
                        <div className={styles['schedule-editor-list-page-empty']}>
                            <Title className={styles['schedule-editor-list-page-empty-title']}>
                                Schedule your first Lead Nurturing blast!
                            </Title>
                        </div>
                    )}
                </AppContent>
            </AppPage>
            <HelpDrawer
                settings={{
                    title: 'Scheduled Blasts Overview',
                    embedFile: '/lead-nurturing/scheduled-blasts-overview.html'
                }}
            />
            <Modal
                isOpen={modal.active === 'createBlastSchedule'}
                onAfterClose={() => dispatch({ name: 'closeModal' })}
                closeButton={false}
            >
                {state.canCreate ? (
                    <Form
                        initialValues={{
                            name: ''
                        }}
                        onSubmit={handleCreateBlastSchedule}
                        validationSchema={CreateBlastScheduleFormValidationSchema}
                    >
                        {(formikProps: FormikProps<CreateBlastScheduleForm>) => {
                            return (
                                <>
                                    <ModalHeader title={<>Create Scheduled Blast</>} />
                                    <InputText
                                        autoFocus={true}
                                        name="name"
                                        label="Scheduled Blast Name"
                                        formikProps={formikProps}
                                    />
                                    <ModalActions>
                                        <div className="flex-spacer" />
                                        <Button onClick={() => dispatch({ name: 'closeModal' })}>Cancel</Button>
                                        <ButtonPrimary type="submit">Create Scheduled Blast</ButtonPrimary>
                                    </ModalActions>
                                </>
                            );
                        }}
                    </Form>
                ) : (
                    <>
                        <ModalHeader
                            title={
                                <>
                                    <Icon name="error" className="icon color-danger" />
                                    Cannot Create Scheduled Blast
                                </>
                            }
                        />
                        <p>
                            There is no active Sender Profile for this company. Sender Profiles are required for sending
                            bulk email.
                        </p>
                        <ModalActions>
                            <div className="flex-spacer" />
                            <Button onClick={() => dispatch({ name: 'closeModal' })}>Close</Button>
                        </ModalActions>
                    </>
                )}
            </Modal>
            <Modal
                isOpen={modal.active === 'archiveBlastSchedule'}
                onAfterClose={() => dispatch({ name: 'setModalData', payload: {} })}
                closeButton={false}
            >
                <ModalHeader title={`Archive Scheduled Blast Results`} />

                <p className="text">
                    Are you sure you want to archive the results from{' '}
                    <strong className={styles['bold']}>{modal.data.selectedSchedule?.name}</strong>? Reports will no
                    longer be visible for this blast.
                </p>
                <ModalActions>
                    <div className="flex-spacer" />
                    <Button onClick={() => dispatch({ name: 'closeModal' })}>Cancel</Button>
                    <ButtonDanger onClick={() => handleArchiveBlastSchedule(modal.data.selectedSchedule)}>
                        Archive Scheduled Blast
                    </ButtonDanger>
                </ModalActions>
            </Modal>
            <Modal
                isOpen={modal.active === 'deleteBlastSchedule'}
                onAfterClose={() => dispatch({ name: 'setModalData', payload: {} })}
                closeButton={false}
            >
                <ModalHeader title={`Delete Scheduled Blast`} />

                <p className="text">
                    Are you sure you want to delete{' '}
                    <strong className={styles['bold']}>{modal.data.selectedSchedule?.name}</strong>?
                </p>
                <ModalActions>
                    <div className="flex-spacer" />
                    <Button onClick={() => dispatch({ name: 'closeModal' })}>Cancel</Button>
                    <ButtonDanger onClick={() => handleDeleteBlastSchedule(modal.data.selectedSchedule)}>
                        Delete Scheduled Blast
                    </ButtonDanger>
                </ModalActions>
            </Modal>
        </>
    );
});
